.infoItem {
  flex: auto;
  min-width: 40%;
  margin: 0;
  display: flex;
  padding: var(--spacing-3) 0;
  border-bottom: 1px solid var(--color-neutral-300);
  align-items: center;

  @media (--tablet-l) {
    flex: 1;
    gap: var(--spacing-6);
  }
}

.label {
  flex: 1 1 100%;
}

.value {
  flex: 1 1 100%;
  font-weight: 500;

  &.capitalizedName {
    text-transform: capitalize;
  }

  & svg {
    margin-bottom: -5px;
  }
}
