.wrapper {
  position: relative;
  height: 380px;
  text-decoration: none;
  display: block;

  & > .heroBannerContainer {
    display: block;
    height: 100%;
  }
  @media (--mobile) {
    height: 464px;
  }
  @media (--tablet-s) {
    height: 300px;
  }
  @media (--tablet-l) {
    height: 380px;
  }
  @media (--desktop) {
    height: 380px;
  }
}

.heroContentWrapper {
  position: relative;
  z-index: 1;
  @media (--mobile) {
    top: 64px;
  }
  @media (--tablet-s) {
    top: 64px;
  }
  @media (--tablet-l) {
    top: 88px;
  }
  & > h1,
  & > div {
    font-size: 60px;
    padding: 5px;
    margin: 0;
    @media (--tablet) {
      font-size: 48px;
    }
    @media (--mobile) {
      font-size: 32px;
    }
  }
  & > h1 {
    display: inline-block;
    margin-bottom: var(--spacing-2);
  }

  & > div {
    font-weight: 700;
    display: table;
  }
}

.fbImageContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.heroAd {
  --max-content-width: 1312px;

  position: relative;
  display: block;
  margin-left: -20px;
  width: 100vw;
  height: 466px;

  [data-service-consumer='native'] & {
    background: var(--color-primary-100);
    background: var(--color-tarmac-grey-100);

    & [class*='Ad_wrapper'] {
      background: var(--color-primary-700);
      background: var(--color-tarmac-grey-100);

      opacity: 0;
      transition: 0.6s;
      transition-delay: 0.01s;

      &:has(.fbImageContainer),
      &:has(iframe) {
        opacity: 1;
      }
    }
  }

  @media (--tablet) {
    margin-left: -24px;
    height: 380px;
  }

  @media (--tablet-l) {
    margin-left: -32px;
  }

  @media (--desktop-l) {
    margin-left: calc(-1 * (100vw - var(--max-content-width)) / 2);
  }

  > div {
    height: 466px;
    background: white;

    @media (--tablet) {
      height: 380px;
    }
  }
}

.imageContainer {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);

  position: absolute;
  top: 0;

  width: 100vw;
  left: calc(var(--sidePadding) * -1);

  @media (--tablet) {
    --internalGap: var(--spacing-6);
  }

  @media (--tablet-l) {
    --internalGap: var(--spacing-8);
  }

  @media (--desktop-m) {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
  }

  @media (--desktop-l) {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
  }
}

.image {
  width: 100vw;
  height: 464px;
  object-fit: cover;

  @media (--mobile) {
    height: 464px;
  }
  @media (--tablet-s) {
    height: 380px;
  }
}
