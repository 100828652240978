.financeType {
  text-transform: uppercase;
  margin-right: var(--spacing-1);
}

.modalContainer {
  @media (--tablet-l) {
    height: 700px;
    width: 800px;
  }
}

.modalBodyClassName {
  overflow: auto;
}
