.financeOptionsLink {
  margin: 0;
  align-self: flex-start;
  display: contents;
  & span {
    text-decoration: underline;
  }

  &[data-source='false'] {
    color: inherit;
    display: block;
    margin-top: var(--spacing-3);
    @media (--tablet-l) {
      margin-top: var(--spacing-4);
    }
  }
}
