.fincalcPoweredBy {
  & > h4 {
    margin: 0;
  }
  margin-bottom: var(--spacing-6);
  @media (--desktop) {
    margin-bottom: var(--spacing-6);
  }
}

.termsWrapper {
  line-height: 12px;

  a {
    color: var(--color-neutral-700);
  }
}
